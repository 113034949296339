@import "/src/scale-grid-column";

.blasmaschine_zeiten_verwalten_modal {
  display: grid;
  grid-template-columns: repeat(60, 1fr);
  gap: 5px;
  grid-template-areas:
    scale-grid-column(30, "nummer werkstoff_database_id")
    scale-grid-column(20, "blasmaschine_database_id blasform_database_id gewicht")
    scale-grid-column(30, "hals_teil_database_id kunde_database_id")
    scale-grid-column(20, "programm_zeit programm_hub maschinen_zyklus")
    scale-grid-column(60, "zeitensollwerte_label")
    scale-grid-column(15, "stueck_pro_stunde blasdruck verz_stanze_vor zeit_bandstop")
    scale-grid-column(15, "maschinen_taktgeber blaszeit_ist verz_stanze_zur verz_sturtzluft")
    scale-grid-column(15, "maschinen_laufkontrolle standzeit verz_greifer_zu dauer_stuetzluft")
    scale-grid-column(15, "verz_trennen_1_vor verz_form_zu verz_greifer_auf_se verz_dekomp")
    scale-grid-column(15, "verz_trennen_1_zur lueftzeit_dorn verz_greifer_auf_le dauer_dekomp")
    scale-grid-column(15, "zeitglied_trennen_1 lueftzeit_form verz_ent_senk verz_pneum_1")
    scale-grid-column(15, "verz_blas_blad verz_wagen_ab verz_dorn_hoch dauer_pneum_1")
    scale-grid-column(30, "vorblaszeit verz_wagen_hoch")
    scale-grid-column(60, "extruder_sondertemperaturen_label")
    scale-grid-column(60, "extruder_sondertemperaturen")
    scale-grid-column(60, "wbk_label")
    scale-grid-column(6, "wbk_0 wbk_1 wbk_2 wbk_3 wbk_4 wbk_5 wbk_6 wbk_7 wbk_8 wbk_9")
    scale-grid-column(6, "wbk_10 wbk_11 wbk_12 wbk_13 wbk_14 wbk_15 wbk_16 wbk_17 wbk_18 wbk_19")
    scale-grid-column(6, "wbk_20 wbk_21 wbk_22 wbk_23 wbk_24 wbk_25 wbk_26 wbk_27 wbk_28 wbk_29")
    scale-grid-column(60, "wbk_graph")
    scale-grid-column(60, "bemerkung");

  & >div {
    margin-bottom: 0px;
  }
}
