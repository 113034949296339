.abstaende_verwalten_modal {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  grid-template-areas:
    "nummer flaschen_form_database_id"
    "kmf_label kmf_label"
    "kopf_messer_form_abstand_a abstaende_image"
    "kopf_messer_form_abstand_b abstaende_image";

  & >div {
    margin-bottom: 0px;
  }
}
