.centered_inline_buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1vw;
}

.verpackungs_vorschrift_information_table td {
  padding: 5px !important;
  padding-left: 0px !important;
}

.verpackungs_vorschrift_information_table-hidden_row {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  opacity: 0;
 }
