.grid {
  display: grid;
  grid-template-areas: "sidebar content";
  grid-template-columns: 240px auto;
  grid-template-rows: 100% 100%;
  width: 100%;
  height: 100%;
}

.component {
  height: 100%;
  padding: 1rem;
}

.body_dark_mode {
  background-color: gray;
  scrollbar-color: transparent transparent
}
