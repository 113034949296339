.artikel_information_table td {
  padding: 5px !important;
  padding-left: 0px !important;
}

.artikel_information_table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #ddd;
}
