.plantafel {

  .ag-header-cell {
    border-right: 1px solid #969696;
  }

  .ag-cell {

    border-right: 1px solid #969696 !important;

    &[col-id="BLM"] {
      background-color: rgb(255, 253, 150);
    }

    &[col-id="PAU_Start"],
    &[col-id="PAU_Ende"] {
      background-color: rgb(192, 192, 192);
      color: rgb(0, 26, 245);
    }

    &[col-id="BD_d"] {
      background-color: rgb(194, 255, 255);
    }

    &[col-id="P_d"] {
      background-color: rgb(196, 255, 200);
    }

    &[col-id="Menge_Gesamt"] {
      background-color: rgb(192, 192, 192);
    }

    &.highlight_pau_nr_if_pau_is_new {
      background-color: green;
    }

    &.highlight_pau_menge_if_different {
      color: blue;
    }

    &.highlight_lt_ku_if_expected_finished_date_is_to_late {
      background-color: lightcoral;
    }

    &.highlight_lt_ku_if_db_nr_set_and_14_days_buffer {
      background-color: red;
    }

    &.highlight_p_d_if_db_nr_set {
      color: red;
    }
  }

  .ag-header-cell {
    background-color: rgb(192, 192, 192);

    &[col-id="Bestell_Menge"],
    &[col-id="PAU_Menge"] {
      background-color: rgb(163, 203, 250);
    }

    &[col-id="BLM"],
    &[col-id="PAU_Start"],
    &[col-id="PAU_Ende"] {
      background-color: rgb(255, 252, 57);
    }

    &[col-id="BD_d"],
    &[col-id="P_d"],
    &[col-id="MengeGut"]  {
      background-color: rgb(117, 250, 76);
    }

    &[col-id="LT_KU"],
    &[col-id="MengeGesamt"] {
      background-color: rgb(247, 205, 70);
    }

    &[col-id="Zaehler_Stand_Umbau_Start"],
    &[col-id="Zaehler_Stand_Prod_Start"],
    &[col-id="Zaehler_Stand_Prod_Ende"] {
      background-color: rgb(117, 251, 253);
    }

    &[col-id="MengeEinricht_Schrott"],
    &[col-id="MengeAusschuss"] {
      background-color: rgb(234, 51, 35);
    }

    &[col-id="LT"],
    &[col-id="Menge_Gesamt"] {
      background-color: rgb(255, 194, 45);
    }

    &[col-id="PAU_Menge"],
    &[col-id="Bestell_Menge"] {
      background-color: rgb(137, 199, 252);
    }

    &[col-id="Faerbungs_Grad"],
    &[col-id="Zähler_Stand_Umbau"],
    &[col-id="Zähler_Stand_Prod_Start"],
    &[col-id="Zähler_Stand_Prod_Ende"] {
      background-color: rgb(0, 255, 254);
    }

    &[col-id="Menge_Einricht_Schrott"],
    &[col-id="Menge_Ausschuss"] {
      background-color: rgb(255, 0, 18);
      color: rgb(255, 255, 255);
    }

    &[col-id="Menge_Gut"] {
      background-color: rgb(0, 254, 53);
    }

  }

  .ag-row-group {

    .ag-cell {
      background-color: rgb(150, 150, 150);
      color: rgb(255, 254, 84);
      font-weight: bold;
    }
  }

  .e-control-wrapper {
    color: inherit !important;
  }
}
