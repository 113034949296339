#root {
  height: 100%;
}

html {
  height: 100%;
}

body {
  height: 100%;
  background-color: #f9fafc;
  margin: 0;
}

.container {
  display: flex;
  height: 100%;
  justify-content: center;
}

@keyframes spinner-keyframes {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.alert {
  pre {
    margin-bottom: 0;
  }
}

.validation-errors-renderer {
  margin-top: -4px;
  margin-bottom: 4px;
}

.bp4-dialog {
  width: auto;
  max-width: 70%;
  min-width: 30%;
}

