.grid_container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
}

.grid_container__header {
  margin-bottom: 5px;
  display: grid;
  grid-template-columns: auto min-content;
}

.grid_container__header h1 {
  margin-top: 0px;
}

.rueckmelde-field__button {
  display: block;
  width: 100%;
  padding: 0px;
  line-height: normal;
  margin: 1px;
}

.hinweis-cell {
  display: grid;
  grid-template-columns: 1fr auto;
  justify-content: space-between;
}

.hinweis-cell__text {
  overflow: hidden;
}

.hinweis-cell__button {
  font-size: small;
  padding: 0px 5px;
  align-self: center;
  margin-left: 5px;
}

.row_actions_box {
  display: flex;
  grid-gap: 5px;
}

.epic_highlighted_drucken_button {
  color: #2bff60 !important;

  animation-name: button_color_transition !important;
  animation-duration: .4s;
  animation-iteration-count: infinite;
}

@keyframes button_color_transition {
  0% {background-color: #d800ad;}
  1% {background-color: #ea2aff;}
  50% {background-color: #ea2aff;}
  51% {background-color: #d800ad;}
  100% {background-color: #d800ad;}
}
