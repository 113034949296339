.form_card {
    margin: auto;
    padding: 10px;
    min-height: 13rem;
    display: grid;
    align-items: center;
    margin-top: 0px;
}

.form_card__body {
  box-shadow: inset 0 1px 0 0 rgba(16, 22, 26, 0.15);
  padding-top: 5px;
}

.form_card__footer {
  display: grid;
  justify-items: right;
}

.two_panel_form_card_content {
  display: grid;
  grid-template-columns: 1fr min-content 1fr;
  column-gap: 20px;
}

.two_panel_form_card__seperator {
  height: 100%;
  width: 1px;
  background-color: lightgray;
}
