@import "/src/scale-grid-column";

.einstellparamter_blasform_verwalten_modal {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5px;
  grid-template-areas:
    "nummer blasform_database_id hals_teil_database_id gewicht"
    "blasdornspitze_label blasdornspitze_label blasdornspitze_label duese_label"
    "blasdornsp_l1 blasdornsp_d1 blasdornspitze_image duesen_bezeichnung"
    "blasdornsp_l2 blasdornsp_d2 blasdornspitze_image duesen_durchmesser"
    "blasdornsp_l3 blasdornsp_radius . profilierung"
    "schneidring_durchmesser . . ."
    scale-grid-column(4, "abschlagbacken_label")
    "abschlagbacken_konisch abschlagbacken_d1 abschlagbacken_image abschlagbacken_image"
    "backenhoehe_h1 abschlagbacken_d2 abschlagbacken_image abschlagbacken_image"
    "steghoehe_h2 unterlegbleche abschlagbacken_image abschlagbacken_image"
    scale-grid-column(4, "hinweis");

  & >div {
    margin-bottom: 0px;
  }
}
