.logo {
  width: 100%;
  padding: 10px;
}

.logo:hover {
  cursor: pointer;
}

.sidebar {
  transition: left .5s;
  width: 100%;
  grid-area: sidebar;
  background-color: rgb(14,40,58);
  color: white;
}

.navigation {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
}

.navbuttons {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0 10px;
  flex-grow: 1;
}

.navbutton {
  margin-bottom: 10px;
  height: 50px;
}

.bottom_buttons {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0 10px;
  flex-grow: 0;
}
