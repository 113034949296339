@function scale-grid-column($n, $raw-definition) {
  $values: str-split($raw-definition, " ");
  $thing: '';
  @each $value in $values {
      @for $count from 1 through $n {
          $thing: #{$thing} #{$value};
      }
  }
  @return "#{$thing}";
}
@function str-split($string, $separator) {
  $split-arr: ();
  $index : str-index($string, $separator);
  @while $index != null {
      $item: str-slice($string, 1, $index - 1);
      $split-arr: append($split-arr, $item);
      $string: str-slice($string, $index + 1);
      $index : str-index($string, $separator);
  }
  $split-arr: append($split-arr, $string);
  @return $split-arr;
}
