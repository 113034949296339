.content-container {
  overflow-y: auto;
  max-height: 60vh;
}

.content-container__table {
  border-collapse: collapse;
  width: 100%;
}

.content-container__table td,
.content-container__table th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.hinweisModalContainer {
  min-width: 50%;
  max-width: 66%;
}
