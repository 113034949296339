.kundenauftraege_pending_callout {
  margin-bottom: 1rem;
}

.kundenauftraege_pending_callout h4,
.kundenauftraege_pending_callout p,
.kundenauftraege_pending_callout ul {
  margin-bottom: 0 !important;
}

.kundenauftraege_pending_callout p {
  margin-top: 5px;
}

.kundenauftraege_pending_callout ul {
  padding-left: 1em;
}
