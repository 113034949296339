.lagerpostenButton {
  margin-top: 10px;
  margin-bottom: 10px;
}

.lagerpostenButtonLoading {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 5px;
}
