@import "/src/scale-grid-column";

.blasform_verwalten_modal {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5px;
  grid-template-areas:
    "nummer prioritaet nummer_intern wechselbares_blindenzeichen"
    "flaschen_typ_database_id stich anzahl_kavitaeten lagerplatz_nummer"
    scale-grid-column(4, "blindenzeichen")
    scale-grid-column(4, "nest_nummern_label")
    scale-grid-column(4, "nest_nummern")
    scale-grid-column(4, "hinweis");


  & >div {
    margin-bottom: 0px;
  }
}
