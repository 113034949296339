.loadingIndicator {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loadingIndicator span {
  padding-top: 20px;
  font-size: 1.5rem;
  font-weight: bold;
  color: #000;
}
